import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useYahooApi } from './hooks';
import { TopNavbar, BottomNavbar } from './components';
import { Roster, Matchup, Players, Standings, Login } from './pages';

export const App = () => {
    const { isAuthenticated, checkAuthStatus, data } = useYahooApi();
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        if(!data) checkAuthStatus();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY > lastScrollY && currentScrollY > 5) {
                setIsNavbarVisible(false);
            } else {
                setIsNavbarVisible(true);
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]);

    if (!isAuthenticated) {
        return (
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
        );
    }

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            overflow: 'hidden',
            overscrollBehavior: 'contain',
            paddingTop: '50px',
            paddingBottom: '56px',
        }}>
           
            <TopNavbar isVisible={true} />
            {/* <TopNavbar/> */}

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >                

                <Routes>
                    <Route path="/" element={<Roster />} />
                    <Route path="/matchup" element={<Matchup />} />
                    <Route path="/players" element={<Players />} />  
                    <Route path="/leagues/:key/standings" element={<Standings />} /> 
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Box>
            
            <BottomNavbar />
        </Box>
    );
};