import { create } from "zustand";
import axios from 'axios';

export const useYahooApi = create((set, get) => {
    return {
        isAuthenticated: false,
        data: null,

        checkAuthStatus: async () => {
            const url = '/auth/yahoo/status';
            const response = await axios.get(url);
            set({isAuthenticated: response.data.isAuthenticated});
        },
        
        setData: async () => {
            const season = '2024';
            let url = `/api/yahoo/leagues/${season}`;
            let response = await axios.get(url);
            const leagues = response.data.fantasy_content.users.user.games.game.leagues.league;
            const selectedLeague = leagues[0];
            const rosterWeek = selectedLeague.current_week;

            url = `/api/yahoo/leagues/${selectedLeague.league_key}/standings`;
            response = await axios.get(url);
            const teams =  response.data.fantasy_content.league.standings.teams.team;
            const selectedTeam = teams.find(team => team.team_key === selectedLeague.teams.team.team_key)

            const key = selectedLeague.teams.team.team_key;
            url = `/api/yahoo/teams/${key}/matchups`
            response = await axios.get(url);
            const myMatchups = response.data.fantasy_content.team.matchups.matchup
            const selectedMatchup = myMatchups.find(m => m.week === selectedLeague.current_week);

            selectedMatchup.teams.team.forEach(async (tm, idx) => {
                const key = tm.team_key;
                let url = `/api/yahoo/teams/${key}/roster?week=${selectedMatchup.week}`;
                let response = await axios.get(url);
                let roster = response.data.fantasy_content.team.roster.players.player;

                url = `/api/yahoo/leagues/${selectedLeague.league_key}/players/stats`;
                const requestBody = { players: roster.map(player => player.player_key), week: selectedMatchup.week };
                const headers = { headers: { 'Content-Type': 'application/json' } }; 
                response = await axios.post(url, requestBody, headers);
                const players = response.data.fantasy_content.league.players.player;
                roster = roster.map(p => ({
                    ...p,
                    ...players.find(p2 => p2.player_key === p.player_key) 
                }));

                if(idx === 0) {
                    set(state => ({
                        ...state,
                        data: {
                            ...state.data,
                            roster, rosterWeek
                        }
                    }));
                } else {
                    set(state => ({
                        ...state,
                        data: {
                            ...state.data,
                            opponentRoster: roster,
                            leagues, selectedLeague, 
                            teams, selectedTeam, 
                            myMatchups,
                            selectedMatchup
                        }
                    }))
                }
            })
        },

        setLeagueMatchups: async () => {
            const { data } = get();
            const url = `/api/yahoo/leagues/${data.selectedLeague.league_key}/matchups?week=${data.selectedLeague.current_week}`
            const response = await axios.get(url);
            const leagueMatchups = await response.data.fantasy_content.league.scoreboard.matchups.matchup;
            set( state => ({
                ...state,
                data: {
                    ...state.data,
                    leagueMatchups
                }
            }));
        },

        updateRoster: async (week) => {
            const { data } = get();
            
            const key = data.selectedTeam.team_key;
            let url = `/api/yahoo/teams/${key}/roster?week=${week}`;
            let response = await axios.get(url);
            let roster = response.data.fantasy_content.team.roster.players.player;
            url = `/api/yahoo/leagues/${data.selectedLeague.league_key}/players/stats`;
            const requestBody = { players: roster.map(player => player.player_key), week: week };
            const headers = { headers: { 'Content-Type': 'application/json' } }; 
            response = await axios.post(url, requestBody, headers);
            roster = roster.map(p => ({
                ...p,
                ...response.data.fantasy_content.league.players.player.find(p2 => p2.player_key === p.player_key) 
            }));

            set(state => ({
                ...state,
                data: {
                    ...state.data,
                    rosterWeek: week,
                    roster,
                }
            }));            
        },
        
        updateSelectedMatchup: async (week) => {
            const { data } = get();

            const selectedMatchup = data.myMatchups.find(matchup => matchup.week === week);
            selectedMatchup.teams.team.forEach(async (tm, idx) => {
                const key = tm.team_key;
                let url = `/api/yahoo/teams/${key}/roster?week=${week}`;
                let response = await axios.get(url);
                let roster = response.data.fantasy_content.team.roster.players.player;
                
                url = `/api/yahoo/leagues/${data.selectedLeague.league_key}/players/stats`;
                const requestBody = { players: roster.map(player => player.player_key), week: week };
                const headers = { headers: { 'Content-Type': 'application/json' } }; 
                response = await axios.post(url, requestBody, headers);
                roster = roster.map(p => ({
                    ...p,
                    ...response.data.fantasy_content.league.players.player.find(p2 => p2.player_key === p.player_key) 
                }));

                if(idx === 0) {
                    set(state => ({
                        ...state,
                        data: {
                            ...state.data,
                            roster
                        }
                    }))
                } else {
                    set(state => ({
                        ...state,
                        data: {
                            ...state.data,
                            opponentRoster: roster,
                            selectedMatchup
                        }
                    }))
                }
            })
        },

        updateLeagueMatchups: async (week) => {
            const { data } = get();
            const url = `/api/yahoo/leagues/${data.selectedLeague.league_key}/matchups?week=${week}`;
            const response = await axios.get(url);
            
            const leagueMatchups = response.data.fantasy_content.league.scoreboard.matchups.matchup;
            set(state => ({
                ...state,
                data: {
                    ...state.data,
                    leagueMatchups
                }
            }));
        },

        updateSelectedLeague: async (league) => {
            set(state => ({
                ...state,
                data: {
                    ...state.data,
                    selectedLeague: null,
                    leagueMatchups: null,
                }
            }));

            let url = `/api/yahoo/leagues/${league.league_key}/standings`;
            let response = await axios.get(url);
            const teams =  response.data.fantasy_content.league.standings.teams.team;
            const selectedTeam = teams.find(team => team.team_key === league.teams.team.team_key);
    
            const key = selectedTeam.team_key;
            url = `/api/yahoo/teams/${key}/matchups`
            response = await axios.get(url);
            const myMatchups = response.data.fantasy_content.team.matchups.matchup
            const selectedMatchup = myMatchups.find(m => m.week === league.current_week);
            
            selectedMatchup.teams.team.forEach(async (tm, idx) => {
                const key = tm.team_key;
                let url = `/api/yahoo/teams/${key}/roster?week=${selectedMatchup.week}`;
                let response = await axios.get(url);
                let roster = response.data.fantasy_content.team.roster.players.player;

                url = `/api/yahoo/leagues/${league.league_key}/players/stats`;
                const requestBody = { players: roster.map(player => player.player_key), week: selectedMatchup.week };
                const headers = { headers: { 'Content-Type': 'application/json' } }; 
                response = await axios.post(url, requestBody, headers);
                roster = roster.map(p => ({
                    ...p,
                    ...response.data.fantasy_content.league.players.player.find(p2 => p2.player_key === p.player_key) 
                }));

                if(idx === 0) {
                    set(state => ({
                        ...state,
                        data: {
                            ...state.data,
                            selectedTeam,
                            myMatchups, 
                            roster,
                            selectedMatchup,
                            teams, 
                            rosterWeek: league.current_week
                        }
                    }))
                } else {
                    set(state => ({
                        ...state,
                        data: {
                            ...state.data,
                            opponentRoster: roster,
                            selectedLeague: league,

                        }
                    }))
                }
            });
        },

        getRoster: async (team, week) => {
            const { data } = get();
            let url = `/api/yahoo/teams/${team.team_key}/roster?week=${week}`;
            let response = await axios.get(url);
            let roster = response.data.fantasy_content.team.roster.players.player;
            url = `/api/yahoo/leagues/${data.selectedLeague.league_key}/players/stats`;
            const requestBody = { players: roster.map(player => player.player_key), week: week };
            const headers = { 'Content-Type': 'application/json' }; 
            response = await axios.post(url, requestBody, {headers});
            roster = roster.map(p => ({
                ...p,
                ...response.data.fantasy_content.league.players.player.find(p2 => p2.player_key === p.player_key) 
            }));
            return roster;
        },
    }
});