import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { FiChevronLeft } from 'react-icons/fi';

import { MatchupsSkeleton, SelectWeekButtonGroup } from '../components';
import { Matchup } from './Matchup';
import { useModal, useYahooApi } from '../hooks';

export const Matchups = () => {
    const [isLoadingMatchups, setIsLoadingMatchups] = useState(true);
    const { data, updateLeagueMatchups, getRoster, setLeagueMatchups } = useYahooApi();
    const { openModal } = useModal();
    const [matchupsWeek, setMatchupsWeek] = useState(data.selectedLeague.current_week);

    const getTeamRecord = (team) => {
        const tm = data.teams.find(t => t.team_key === team.team_key);
        return `${tm.team_standings.outcome_totals.wins}-${tm.team_standings.outcome_totals.losses}-${tm.team_standings.outcome_totals.ties}`;
    };

    const updateMatchups = (week) => {
        updateLeagueMatchups(week);
        setMatchupsWeek(week);
    };

    useEffect(() => {
        if(!data.leagueMatchups) {
            setLeagueMatchups();
        } else {
            setIsLoadingMatchups(false);
        }
    }, [data.leagueMatchups]);

    if(isLoadingMatchups) 
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <MatchupsSkeleton/>
            </Box>
        );
   

    const renderMatchupDetails = async (matchup) => {
        const roster1 = await getRoster(matchup.teams.team[0], matchup.week);
        const roster2 = await getRoster(matchup.teams.team[1], matchup.week);
        openModal({
            content: <MatchupDetails roster1={roster1} roster2={roster2} selectedMatchup={matchup}/>, 
            direction: 'right',
            backButton: <BackButton/>
        });
    };
    
    return (
        <Box sx={{ height: 'calc(100vh - 48px)', display: 'flex', flexDirection: 'column' }}>
            <Stack gap={3} sx={{ flexGrow: 1, paddingX: 3, paddingY: 2 }}>
                <Box sx={{ paddingY: 0.5 }}>
                    <SelectWeekButtonGroup 
                        selectedWeek={matchupsWeek} 
                        setSelectedWeek={(week) => updateMatchups(week)}
                    />
                </Box>
                <Stack gap={4}>
                    {data.leagueMatchups.map((match, idx) => (
                        <Button 
                            key={idx} 
                            sx={{ display: 'block', width: '100%', textAlign: 'left', padding: 0 }}
                            onClick={() => renderMatchupDetails(match)}
                        >
                            <Box
                                id='matchup-container'
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    paddingX: 2.5,
                                    paddingY: 2,
                                    border: 'solid 1px black',
                                    borderRadius: '10px'
                                }}
                            >
                                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 4 }}>
                                    {[0, 1].map((teamIndex) => (
                                        <Box key={teamIndex} sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', color: 'black' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                <Typography fontSize={12}>
                                                    {(data.teams.find(t => t.team_key === match.teams.team[teamIndex].team_key))?.team_standings.rank}
                                                </Typography>
                                                <img 
                                                    src={match.teams.team[teamIndex].team_logos.team_logo.url} 
                                                    style={{ width: 40, height: 40, borderRadius: 100 }} 
                                                    alt={`Team ${teamIndex + 1} logo`}
                                                />
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gapX: 1 }}>
                                                    <Typography fontSize={13} fontWeight={600}>
                                                        {match.teams.team[teamIndex].name} ({(Number(match.teams.team[teamIndex].win_probability) * 100).toFixed()}%)
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                                        { match.teams.team[teamIndex].managers.manager.nickname && 
                                                            <Typography fontSize={12}>
                                                                {match.teams.team[teamIndex].managers.manager.nickname}
                                                            </Typography>
                                                        }
                                                        <Typography fontSize={12}>{getTeamRecord(match.teams.team[teamIndex])}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: 'fit-content', gap: 1 }}>
                                                <Typography fontSize={12}>{match.teams.team[teamIndex].team_points.total}</Typography>
                                                <Typography fontSize={12}>{match.teams.team[teamIndex].team_projected_points.total}</Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Button>
                    ))}
                </Stack>
            </Stack>
        </Box>
    );
};

const BackButton = () => {
    const { openModal } = useModal();

    const renderMatchups = () => openModal({content: <Matchups/>, renderBackButton: true, direction: 'left'});

    return (
        <IconButton
            color="inherit"
            onClick={renderMatchups}
            aria-label="back"
        >
            <FiChevronLeft color='#FFFFFF'/>
        </IconButton>
    )
};

const MatchupDetails = ({roster1, roster2, selectedMatchup}) => {
    return <Matchup roster1={roster1} roster2={roster2} selectedMatchup={selectedMatchup} hideButton={true}/>;
};