import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { BasicLoading, SelectWeekButtonGroup } from '../components';
import { Matchups } from './Matchups';

import { useYahooApi, useModal } from '../hooks';

export const Matchup = ({ roster1, roster2, selectedMatchup, hideButton }) => {

    const { data } = useYahooApi();
    
    const [myLineup, setMyLineup] = useState(null);
    const [opponentLineup, setOpponentLineup] = useState(null);
    const [positions, setPositions] = useState(null);

    useEffect(() => {
        const lineup1 = getLineup(roster1 || data.roster);
        const lineup2 = getLineup(roster2 || data.opponentRoster);
        const positions = updateLineupsAndGetPositions(lineup1, lineup2);
        setMyLineup(lineup1);
        setOpponentLineup(lineup2);
        setPositions(positions);
    }, [data.opponentRoster])

    const getLineup = (roster) => {
        const QB = roster.find(player => player.selected_position.position === 'QB') || {name: {first: 'EMPTY'}, selected_position: {position: 'QB'}};
        let WR = roster.filter(player => player.selected_position.position === 'WR');
        while (WR.length < 2) {
            WR.push({ name: { first: "EMPTY" }, selected_position: {position: 'WR'}});
        }
        let RB = roster.filter(player => player.selected_position.position === 'RB');
        while (RB.length < 2) {
            RB.push({ name: { first: "EMPTY" }, selected_position: {position: 'RB'} });
        }
        const TE = roster.find(player => player.selected_position.position === 'TE') || {name: {first: 'EMPTY'}, selected_position: {position: 'TE'}};
        const WRT = roster.find(player => player.selected_position.position === 'W/R/T') || {name: {first: 'EMPTY'}, selected_position: {position: 'W/R/T'}};
        const K = roster.find(player => player.selected_position.position === 'K') || {name: {first: 'EMPTY'}, selected_position: {position: 'K'}};
        const DEF = roster.find(player => player.selected_position.position === 'DEF') || {name: {first: 'EMPTY'}, selected_position: {position: 'DEF'}};
        const IDP = roster.find(player => player.selected_position.position === 'D') || {name: {first: 'EMPTY'}, selected_position: {position: 'D'}};
        const hasIDP = IDP.name.first !== 'EMPTY'
        const BN = roster.filter(player => player.selected_position.position === 'BN');
        const IR = roster.filter(player => player.selected_position.position === 'IR');
        return [QB, ...WR, ...RB, TE, WRT, K, hasIDP ? IDP : DEF,  BN, IR];
    };

    const updateLineupsAndGetPositions = (roster, opponentRoster) => {
        const positions = ["QB", "WR", "WR", "RB", "RB", "TE", "W/R/T", "K", roster[8].selected_position.position === 'DEF' ? 'DEF' : 'D' ];

        const bnLength = Math.max(
            roster[9].length,
            opponentRoster[9].length
        );
        while(roster[9].length < bnLength) {
            roster[9].push({name: {first: 'EMPTY'}, selected_position: {position: 'BN'}});
        }
        while(opponentRoster[9].length < bnLength) {
            opponentRoster[9].push({name: {first: 'EMPTY'}, selected_position: {position: 'BN'}});
        }

        const irLength = Math.max(
            roster[10].length,
            opponentRoster[10].length
        );
        while(roster[10].length < irLength) {
            roster[10].push({name: {first: 'EMPTY'}, selected_position: {position: 'IR'}});
        }
        while(opponentRoster[10].length < irLength) {
            opponentRoster[10].push({name: {first: 'EMPTY'}, selected_position: {position: 'IR'}});
        }

        positions.push(Array(bnLength).fill("BN"), Array(irLength).fill("IR"));
        return positions;
    };


    if(!data || !data.selectedMatchup) return;

    if(!positions) return;

    return (
        <Box sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
        }}>
            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto',
                overscrollBehavior: 'contain',
                WebkitOverflowScrolling: 'touch',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            }}>
                <Stack sx={{ width: '100%'}}>
                    <Header hideButton={hideButton} selectedMatchup={selectedMatchup}/>
                    {positions.map((position, idx) =>
                        Array.isArray(position)
                            ? position.map( (_, idx2) => <Row 
                                key={idx2}
                                player={myLineup[idx][idx2]}
                                positionName={idx === 9 ? 'BN' : 'IR'}
                                opponent={opponentLineup[idx][idx2]}
                            /> )
                            : <Row 
                                key={idx}
                                player={myLineup[idx]}
                                positionName={position}
                                opponent={opponentLineup[idx]}
                            />
                    )}
                </Stack>
            </Box>
        </Box>
    );
};

const Header = ({hideButton, selectedMatchup}) => {
    const { openModal } = useModal();
    const { data, updateSelectedMatchup } = useYahooApi();
    const renderMatchups = () => {
            openModal({
                content: <Matchups/>, 
                direction: 'up'
            });
    }

    if(!data || !data.selectedMatchup) return;
    const [matchup, setMatchup] = useState(selectedMatchup || data.selectedMatchup);

    useEffect(() => {
        if(!selectedMatchup) setMatchup(data.selectedMatchup);
        if(selectedMatchup) setMatchup(selectedMatchup);
    }, [data.selectedMatchup, selectedMatchup])
    
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            paddingX: 2.5,
            paddingY: 2,
            gap: 0.5,
            borderBottom: '1px black solid'
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                {matchup.teams.team.map((team, index) => (
                    <Typography key={index} fontSize={14}>{team.name}</Typography>
                ))}
            </Box>            
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <img src={matchup.teams.team[0].team_logos.team_logo.url} style={{ width: '48px', height: '48px', borderRadius: '50%' }} alt="Team Logo" />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
                    <Typography fontSize={32}>{matchup.teams.team[0].team_points.total}</Typography>
                    <Typography sx={{ fontSize: 16, color: 'gray', fontWeight: 600 }}>/</Typography>
                    <Typography fontSize={32}>{matchup.teams.team[1].team_points.total}</Typography>
                </Box>
                <img src={matchup.teams.team[1].team_logos.team_logo.url} style={{ width: '48px', height: '48px', borderRadius: '50%' }} alt="Team Logo" />
            </Box>            
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                <Stack sx={{ alignItems: 'start' }}>
                    <Typography fontSize={14}>{matchup.teams.team[0].team_projected_points.total}</Typography>
                    <Typography fontSize={14}>{parseInt(matchup.teams.team[0].win_probability * 100)}%</Typography>
                </Stack>
                <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Typography fontSize={12}>Live Projected</Typography>
                    <Typography fontSize={12}>Win %</Typography>
                </Stack>        
                <Stack sx={{ alignItems: 'end' }}>
                    <Typography fontSize={12}>{matchup.teams.team[1].team_projected_points.total}</Typography>
                    <Typography fontSize={12}>{parseInt(matchup.teams.team[1].win_probability * 100)}%</Typography>
                </Stack>
            </Box>
            <Box sx={{display: 'flex', gap: 2.5, width: 'fit'}}>
                {!hideButton && <>
                    <SelectWeekButtonGroup selectedWeek={matchup.week} setSelectedWeek={(week) => updateSelectedMatchup(week)}/>

                    <Button 
                        sx={{
                            display: 'flex', 
                            backgroundColor: 'primary.main', 
                            color: 'primary.main', 
                            borderRadius: '20px', 
                            paddingX: 2, 
                            maxHeight: '18px'
                        }}
                        onClick={renderMatchups}
                    >
                        <Typography color='#FFFFFF' fontSize={12} textTransform='none'>All matchups</Typography>
                    </Button> 
                </>}
            </Box>
        </Box>
        
    );
};

const Row = ({player, positionName, opponent}) => {
    return (
        <Box sx={{display: 'flex', borderBottom: '1px solid black', width: '100%', }}> 
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', paddingLeft: 2, paddingRight: 1.5, width: '100%'}}>
                { player.name.first !== 'EMPTY'
                    ? <>
                        <Stack>
                            <Typography letterSpacing='-0.05em' fontSize={14} fontWeight={600} textTransform='uppercase'>
                                {player.primary_position === 'DEF'
                                    ? player.name.first
                                    : `${player.name.first[0]}. ${player.name.last}`
                                }
                            </Typography>
                            <Typography fontSize={12} fontWeight={500}>
                                {player.editorial_team_abbr} - {player.primary_position}
                            </Typography>
                        </Stack>
                        <Box sx={{display: 'flex', height: '100%', justifyContent: 'center'}}>
                            <Typography letterSpacing='-0.05em' fontSize={14} fontWeight={600} textTransform='uppercase'>
                                {player.player_points.total}
                            </Typography>
                        </Box>
                    </>
                    : <Stack sx={{ width: 1, height: 1, alignItems: 'flex-start', justifyContent: 'center' }}>
                        <Typography letterSpacing='-0.05em' fontSize={16} fontWeight={600} textTransform='uppercase'>
                            {player.name.first}
                        </Typography>
                    </Stack>
                }
            </Box>
     
            <Box sx={{
                paddingX: 2,
                paddingY: 2,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                width: 55,
                flexShrink: 0,
            }}>
                <Typography letterSpacing='-0.05em' fontSize={14}>{positionName}</Typography>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'space-between', paddingLeft: 1.5, paddingRight: 2, width: '100%'}}>
                { opponent.name.first !== 'EMPTY'
                    ? <>
                        <Box sx={{display: 'flex', height: '100%', justifyContent: 'center'}}>
                            <Typography letterSpacing='-0.05em' fontSize={14} fontWeight={600} textTransform='uppercase'>
                                {opponent.player_points.total}
                            </Typography>
                        </Box>
                        <Stack sx={{alignItems: 'flex-end'}}>
                            <Typography letterSpacing='-0.05em' fontSize={14} fontWeight={600} textTransform='uppercase'>
                                {opponent.primary_position === 'DEF'
                                    ? opponent.name.first
                                    : `${opponent.name.first[0]}. ${opponent.name.last}`
                                }
                            </Typography>
                            <Typography fontSize={12} fontWeight={500}>
                                {opponent.editorial_team_abbr} - {opponent.primary_position}
                            </Typography>
                        </Stack>
                    </>
                   : <Stack sx={{ width: 1, height: 1, alignItems: 'flex-end', justifyContent: 'center' }}>
                        <Typography letterSpacing='-0.05em' fontSize={16} fontWeight={600} textTransform='uppercase'>
                            {opponent.name.first}
                        </Typography>
                    </Stack>
                }
            </Box>
        </Box>
    );
};