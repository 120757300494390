import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, Box, Typography, Slide } from '@mui/material';

import { useYahooApi } from '../../hooks';

export const TopNavbar = ({ isVisible }) => {
    const [header, setHeader] = useState(null);
    const { data } = useYahooApi();
    const location = useLocation();

    useEffect(() => {
        if(location.pathname === '/') setHeader('Roster');
        else if(location.pathname === '/matchup') setHeader('Matchup');
        else if(location.pathname === '/players') setHeader('Players');
        else setHeader('Standings');
    }, [location.pathname]);
    
    if(!data || !data.selectedLeague) return null;

    return (
        <Slide appear={false} direction="down" in={isVisible} timeout={{enter: 300, exit: 250}}>
            <AppBar position='fixed' color='primary' sx={{ top: 0, bottom: 'auto', height: 48 }}>
                <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                    <Typography fontSize={16}>{header}</Typography>
                    <Typography fontSize={12}>{data.selectedLeague.name}</Typography>
                </Box>
            </AppBar>
        </Slide>
    );
};
